import {
  Box,
  Card,
  CardProps,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material'
import { OddBadge } from '@shared'
import { colors } from '@theme'
import { Reward } from 'parsroyal/types'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/hooks'
import { getUi } from 'store/reducers/uiSlice'
import { StatsProps } from './LiveTournamentStatus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckToSlot } from '@fortawesome/pro-duotone-svg-icons'
import { formatCurrencyAmount } from 'helpers/helpers'

interface TournamentStat {
  playerId: string
  nickname: string
  winOdds: number
}

export const RankTable = (
  props: StackProps & StatsProps & { rankStyle?: 'gradient' | 'flat' }
) => {
  const { stats, rankStyle = 'gradient' } = props
  const { tournamentSection } = useAppSelector(getUi)
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  const prizesV2 = stats?.currencyConditions.find(
    (a) => a.currency === 'IRT'
  )?.prizes

  const customRankStyles: RankProps[] = [
    {
      badge: tournamentSection.rankBadgeList?.[0].url,
      background: `linear-gradient(${
        dir === 'rtl' ? 270 : 90
      }deg, #FFC700 -10.78%, rgba(255, 199, 0, 0) 32.05%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)`,
    },
    {
      badge: tournamentSection.rankBadgeList?.[1].url,
      background: `linear-gradient(${
        dir === 'rtl' ? 270 : 90
      }deg, #393939 -27.42%, rgba(160, 159, 157, 0.863636) -18.56%, rgba(142, 142, 142, 0) 37.54%, rgba(124, 124, 124, 0) 85.95%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)`,
    },
    {
      badge: tournamentSection.rankBadgeList?.[2].url,
      background: `linear-gradient(${
        dir === 'rtl' ? 270 : 90
      }deg, #744111 -27.42%, rgba(189, 102, 53, 0.863636) -18.56%, rgba(252, 123, 82, 0) 37.54%, rgba(124, 124, 124, 0) 85.95%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)`,
    },
  ]

  // TODO: Make a reusable component for each row
  return (
    <Stack
      // dir={'rtl'}
      sx={{
        mt: 1,
        width: '100%',
        position: 'relative',
        overflowY: 'scroll',
        ...props.sx,
      }}
      className='table--container'
    >
      <Stack
        sx={{
          '& > *:not(:last-child)': {
            mb: 0.5,
          },
        }}
      >
        {tournamentSection?.rewardList?.map((item, index) => (
          <Rank
            {...stats?.tournamentData?.[index]}
            {...customRankStyles[index]}
            rank={item.rank}
            prize={
              stats?.version === '2'
                ? prizesV2?.[index]
                : // @ts-ignore
                  stats?.prizes?.[index] || item.prize
            }
            rankImg={item.rankImg}
            rankIndex={index.toString()}
            key={index}
            rankStyle={rankStyle}
            version={stats?.version}
          />
        ))}
      </Stack>
    </Stack>
  )
}

interface RankProps extends Reward {
  background?: string
  badge?: string
  version?: string
}

const Rank = (
  props: RankProps &
    CardProps &
    Partial<TournamentStat> & { rankStyle: 'gradient' | 'flat' }
) => {
  const { rankIndex, rankStyle } = props
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  let styles

  switch (rankStyle) {
    case 'gradient':
      styles = {
        background:
          'linear-gradient(90.67deg, rgba(69, 48, 138, 0.2) 1.82%, rgba(43, 37, 59, 0) 50.58%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
      }
      break

    case 'flat':
      styles = {
        background: colors.gray[4],
      }
      break

    default:
      break
  }

  const reduceRankIndexSize = (rankIndex || '').length > 3

  return rankStyle === 'gradient' ? (
    <RankItemGradient {...props} />
  ) : (
    <RankItemFlat {...props} />
  )
}

const RankItemGradient = (
  props: RankProps & CardProps & Partial<TournamentStat>
) => {
  const { rankIndex } = props
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  const reduceRankIndexSize = (rankIndex || '').length > 3
  return (
    <Card sx={{ borderRadius: 2 }}>
      <Box
        sx={{
          display: 'flex',
          height: 42,
          alignItems: 'center',
          gap: 1,
          p: 1,
          background:
            props.background ||
            'linear-gradient(90.67deg, rgba(69, 48, 138, 0.2) 1.82%, rgba(43, 37, 59, 0) 50.58%), linear-gradient(270deg, #1B1728 7.08%, #5D5378 98.06%)',
          '.MuiTypography-root': {
            fontWeight: dir === 'rtl' ? 500 : 700,
          },
          ...props.sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            gap: '5px',
            '.MuiTypography-root': {
              fontWeight: '700 !important',
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              '& > *:not(:last-child)': {
                marginInlineEnd: 0.5,
              },
              flex: 1,
              textAlign: 'start',
            }}
          >
            <Box sx={{ display: 'flex', width: 32, justifyContent: 'center' }}>
              {props.badge ? (
                <img src={props.badge} alt='' />
              ) : (
                props.rankIndex && (
                  <Typography
                    className='rank--number'
                    sx={{ ...(reduceRankIndexSize && { fontSize: 9 }) }}
                  >
                    {parseInt(props.rankIndex) + 1}
                  </Typography>
                )
              )}
            </Box>
            <Box
              sx={{
                borderLeft: `1px dotted ${colors.primary[5]} `,
                height: '30px',
              }}
            ></Box>
            <Typography
              variant='bodySmall'
              sx={{
                fontSize: 12,
                span: {
                  color: colors.gray[11],
                },
                width: 'auto',
                whiteSpace: 'nowrap',
                ...(props.winOdds && {
                  maxWidth: { xs: '75px', sm: '100%' },
                }),
                ...(props.nickname && {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: {
                    xs: 'calc(20vw - 16px)',
                    sm: 113,
                    md: 103,
                    lg: 300,
                  },
                }),
              }}
            >
              {props.nickname || <span>{props.rank}</span>}
            </Typography>
          </Stack>
          {props.winOdds && (
            <Stack>
              <OddBadge value={props.winOdds} />
            </Stack>
          )}
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 0.5,
              flex: 1,
              '.MuiTypography-root': {
                fontSize: [12, 16],
                ...(reduceRankIndexSize && {
                  fontSize: { xs: 12, sm: 'initial' },
                }),
              },
            }}
          >
            <Typography variant='bodyXSmall'>
              <span
                style={{
                  minWidth: '110px',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  fontSize: '12px',
                  textAlign: dir === 'rtl' ? 'left' : 'right',
                  direction: 'rtl',
                }}
              >
                {props.version === '2' ? (
                  <>
                    <>{i18n.language === 'en' && 'IRT'}</>
                    {` ${formatCurrencyAmount(Number(props.prize))} `}
                    <>{i18n.language === 'fa' && 'تومان'}</>
                  </>
                ) : i18n.language === 'fa' ? (
                  props.prize
                ) : (
                  props.prize?.replace('تومان', 'IRT').replace('تومـان', 'IRT')
                )}
              </span>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Card>
  )
}
const RankItemFlat = (
  props: RankProps & CardProps & Partial<TournamentStat>
) => {
  const { rankIndex } = props
  const { i18n } = useTranslation()
  const dir = i18n.dir(i18n.language)

  const reduceRankIndexSize = (rankIndex || '').length > 3
  return (
    <Box
      sx={{
        display: 'flex',
        height: 42,
        alignItems: 'center',
        gap: 1,
        '.MuiTypography-root': {
          fontWeight: dir === 'rtl' ? 500 : 700,
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: [45, 60],
          justifyContent: 'center',
          img: {
            width: [45, 60],
          },
        }}
      >
        {props.rankImg ? (
          <img src={props.rankImg.url} alt='' />
        ) : (
          props.rankIndex && (
            <Typography
              className='rank--number'
              sx={{ ...(reduceRankIndexSize && { fontSize: 9 }) }}
            >
              {parseInt(props.rankIndex) + 1}
            </Typography>
          )
        )}
      </Box>
      <Card sx={{ borderRadius: 2, flex: 1, background: colors.gray[4] }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            px: 1,
            py: 0.5,
            gap: '5px',
            '.MuiTypography-root': {
              fontWeight: '700 !important',
            },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
              flex: 1,
              textAlign: 'start',
            }}
          >
            <Typography
              variant='bodySmall'
              sx={{
                fontSize: 12,
                span: {
                  color: colors.gray[11],
                },
                width: 'auto',
                whiteSpace: 'nowrap',
                ...(props.winOdds && {
                  maxWidth: { xs: '60px', sm: '100%' },
                }),
                ...(props.nickname && {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }),
              }}
            >
              {props.nickname || <span>{props.rank}</span>}
            </Typography>
          </Stack>
          {props.winOdds && (
            <Stack>
              <OddBadge value={props.winOdds} />
            </Stack>
          )}
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 0.5,
              flex: 1.07,
              '.MuiTypography-root': {
                fontSize: [12, 16],
                ...(reduceRankIndexSize && {
                  fontSize: { xs: 12, sm: 'initial' },
                }),
              },
            }}
          >
            <Typography variant='bodyXSmall'>
              <span
                style={{
                  minWidth: '110px',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  fontSize: '12px',
                  textAlign: dir === 'rtl' ? 'left' : 'right',
                  direction: 'rtl',
                }}
              >
                {props.version === '2' ? (
                  <>
                    <>{i18n.language === 'en' && 'IRT'}</>
                    {` ${formatCurrencyAmount(Number(props.prize))} `}
                    <>{i18n.language === 'fa' && 'تومان'}</>
                  </>
                ) : i18n.language === 'fa' ? (
                  props.prize
                ) : (
                  props.prize?.replace('تومان', 'IRT').replace('تومـان', 'IRT')
                )}
              </span>
            </Typography>
          </Stack>
          <FontAwesomeIcon icon={faCheckToSlot} color={colors.tertiary[6]} />
        </Box>
      </Card>
    </Box>
  )
}
